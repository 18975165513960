
@media only screen and (min-width: 1100px) {
    .request-buttons{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.toolbarButton{
    margin: 0.2rem;
}
.toolbarButton:hover{
    cursor: pointer;
}

.toolbarButtonActive{
    margin: 0.2rem;
    background-color: rgb(153, 82, 82);
    color: rgb(95, 22, 22);
}

.toolbarButtonActive:hover{
    margin: 0.2rem;
    cursor: pointer;
}

.action-box{
    margin: 0 1.6rem;
    padding:0.2rem;
    width: 70%;
    border:1px solid #e2e4e4;
    border-radius:25px;
}